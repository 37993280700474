<template>
    <div class="page unit-page">
        <div class="content">
            <div class="unit-item">
                <img src="/img/ATELIER_typo_horizontal.svg" alt="" class="logo">

                <p class="unit-comment">
                    Atelier는 소프트웨어과 및 정보보호과 학생이 지원할 수 있는 개발 유닛이며, 뛰어난 문제 해결력과 창의력을 지닌 개발자를 양성합니다.
                    Atelier에 속한 부원은 다음 교육과정을 이수합니다.
                </p>

                <div class="curriculum-list">
                    <CurriculumItem
                            v-for="i, n in atCurriculumDataList" :key="n"
                            :curriclumData="i"
                            :unit="'at'"
                    />
                </div>
            </div>
            <div class="unit-item">
                <img src="/img/Pixel_typo_horizeontal.svg" alt="" class="logo">

                <p class="unit-comment">
                    Pixel은 콘텐츠디자인과 학생이 지원할 수 있는 디자인 유닛이며,
                    뛰어난 창의력을 가진 디자이너를 양성합니다.
                    픽셀 부원은 다음 과정을 이수합니다.
                </p>

                <div class="curriculum-list">
                    <CurriculumItem
                            v-for="i, n in pxCurriculumDataList" :key="n"
                            :curriclumData="i"
                            :unit="'px'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CurriculumItem from "./../components/UnitPage/CurriculumItem.vue"

import CurriculumData from "./../Model/CurriculumData"

export default {
    name: "Unit page",
    data() {
        return {
            atCurriculumDataList: [
                new CurriculumData(
                        "Kotlin 교육 과정",
                        "안드로이드를 공부하기 위해서 기본적으로 알아야 하는 언어인 Kotlin을 공부하고 BOJ를 통해 기본 개념을 복습합니다.",
                ),
                new CurriculumData(
                        "Android 기초 교육과정",
                        "Android Studio를 사용한 앱 개발의 필수적인 요소들을 공부하고, 팀 프로젝트를 통해 개발 실력을 향상시킵니다.",
                ),
                new CurriculumData(
                        "웹 프론트엔드 교육과정",
                        "2022년 가장 많이 사용된 웹 프레임워크인 React를 사용한 프론트엔드를 공부합니다.",
                ),
                new CurriculumData(
                        "팀 프로젝트",
                        "디자이너와의 팀 프로젝트를 진행하며 협업의 과정을 이해하고, 서로 소통하는 법을 배웁니다.",
                ),
            ],

            pxCurriculumDataList: [
                new CurriculumData(
                        "UI 및 UX 개념",
                        "UI와 UX에 대한 기본적인 개념과 차이, 그리고 지켜야 하는 기본 원칙에 대해 학습합니다.",
                ),
                new CurriculumData(
                        "Figma 기초 및 심화",
                        "UI 디자인 툴인 Figma의 사용 방법과 다양한 팁을 기초와 심화 단계로 나눠 학습합니다.",
                ),
                new CurriculumData(
                        "디자인 시스템 학습 및 제작",
                        "효율적인 UI 설계를 위한 디자인 시스템에 대해 학습 및 분석하고, 직접 제작해봅니다.",
                ),
                new CurriculumData(
                        "팀 프로젝트",
                        "개발자와 팀 프로젝트를 진행하며 협업의 과정을 이해하고, 서로 소통하는 법을 배웁니다.",
                ),
            ]
        }
    },
    components: {
        CurriculumItem,
    }
}
</script>

<style scoped>
.unit-page {
    padding: 60px 0;
}

.unit-item {
    padding: 60px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 42px;
}

.logo {
    width: 100%;
    max-width: 390px;
}

.unit-comment {
    max-width: 470px;

    font-size: 20px;
    line-height: 30px;
    text-align: center;
}

.curriculum-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
}

@media (max-width: 900px) {
    .curriculum-list {
        grid-template-columns: 1fr;
    }
}
</style>