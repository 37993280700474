import { PortfolioData } from "../Model/PortfolioData.js"

export default [
    new PortfolioData(
        "포폴 제목1",
        "EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!,",
        "Atelier",
        "https://images.otwojob.com/product/h/j/5/hj5pm6HslR63RDN.jpg/o2j/resize/852x622%3E"
    ),
    new PortfolioData(
        "포폴 제목2",
        "EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!,",
        "Atelier",
        "https://images.otwojob.com/product/h/j/5/hj5pm6HslR63RDN.jpg/o2j/resize/852x622%3E"
    ),
    new PortfolioData(
        "포폴 제목3",
        "EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!,",
        "Pixel",
        "https://images.otwojob.com/product/h/j/5/hj5pm6HslR63RDN.jpg/o2j/resize/852x622%3E"
    ),
    new PortfolioData(
        "포폴 제목4",
        "EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!,",
        "Atelier",
        "https://images.otwojob.com/product/h/j/5/hj5pm6HslR63RDN.jpg/o2j/resize/852x622%3E"
    ),
    new PortfolioData(
        "포폴 제목5",
        "EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!,",
        "Pixel",
        "https://images.otwojob.com/product/h/j/5/hj5pm6HslR63RDN.jpg/o2j/resize/852x622%3E"
    ),
    new PortfolioData(
        "포폴 제목6",
        "EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!,",
        "Atelier",
        "https://images.otwojob.com/product/h/j/5/hj5pm6HslR63RDN.jpg/o2j/resize/852x622%3E"
    ),
    new PortfolioData(
        "포폴 제목7",
        "EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!,",
        "Pixel",
        "https://images.otwojob.com/product/h/j/5/hj5pm6HslR63RDN.jpg/o2j/resize/852x622%3E"
    ),
    new PortfolioData(
        "포폴 제목8",
        "EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!EDCAN은 정말 최고야!,",
        "Atelier",
        "https://images.otwojob.com/product/h/j/5/hj5pm6HslR63RDN.jpg/o2j/resize/852x622%3E"
    ),
]
